<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      :data-toggle="collapsableItem ? 'collapse' : ''"
      @click="updateVisible(!visible)"
      style="padding-left: 0px !important"
    >
      <slot name="header">
        <b-row>
          <b-col cols="8">
            <h4 style="color: #636363; font-weight: 500 !important">{{ title }}</h4>
          </b-col>
          <b-col
            align-self="end"
            class="d-flex justify-content-end"
            cols="4"
            style="white-space: nowrap"
          >
            <h4 :class="amountClassProp" v-if="budgetEnabled == false && amount == 0">
              N/A
            </h4>
            <h4 :class="amountClassProp" v-else>
              {{
                Number(amount).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                }) + " /sqft"
              }}
            </h4>
            <span
              :class="diffClassProp"
              v-if="hasChanged"
              style="padding-left: 8px; padding-right: 20px"
            >
              <uil-arrow-up v-if="diffClassProp == 'increase'" />
              <uil-arrow-down v-else />{{
                Number(difference).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </span>
          </b-col>
        </b-row>
      </slot>
    </b-card-header>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
      :appear="false"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BCol,
  BRow,
  BCardTitle,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import { UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCol,
    BRow,
    BCardTitle,
    UilArrowUp,
    UilArrowDown,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    collapsableItem: {
      type: Boolean,
      default: false,
    },
    hasChanged: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    diffClassProp: {
      type: String,
      required: true,
    },
    amountClassProp: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    difference: {
      type: Number,
      required: true,
    },
    budgetEnabled: {
      type: Boolean,
      default: true,
    },
    budgetAEnabled: {
      type: Boolean,
      default: true,
    },
    budgetBEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: "",
      openOnHover: this.$parent.hover,
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
  },
  methods: {
    updateVisible(val = true) {
      if (this.collapsableItem) {
        this.visible = val;
        this.$emit("visible", val);
      }
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
  },
};
</script>
